"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
}
exports.default = convertFileToBase64;
