"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const INITIAL_STATE = {
    user: null,
    allowedRoutes: [],
    notification: null,
};
const stateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'refresh-user':
            return Object.assign(Object.assign({}, state), { user: action.user });
        case 'setAllowedRoutes':
            return Object.assign(Object.assign({}, state), { allowedRoutes: action.allowedRoutes });
        case 'refresh-notification':
            return Object.assign(Object.assign({}, state), { notification: action.notification });
        default:
            return state;
    }
};
exports.default = (0, redux_1.combineReducers)({
    state: stateReducer
});
