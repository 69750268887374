"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.refreshNotification = exports.setAllowedRoutes = exports.refreshUser = void 0;
const refreshUser = (user) => {
    return {
        type: 'refresh-user',
        user: user
    };
};
exports.refreshUser = refreshUser;
const setAllowedRoutes = (allowedRoutes) => {
    return {
        type: 'setAllowedRoutes',
        allowedRoutes: allowedRoutes
    };
};
exports.setAllowedRoutes = setAllowedRoutes;
const refreshNotification = (notification) => {
    return {
        type: 'refresh-notification',
        notification: notification
    };
};
exports.refreshNotification = refreshNotification;
