"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function truncateString(str, num = 20) {
    if (str.length > num) {
        return str.slice(0, num) + "...";
    }
    else {
        return str;
    }
}
exports.default = truncateString;
