"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_router_dom_1 = require("react-router-dom");
function pathInRoutes(routes, path) {
    var _a;
    for (let i = 0; (_a = i < routes.length) !== null && _a !== void 0 ? _a : 0; i++) {
        if ((0, react_router_dom_1.matchPath)({ path: routes[i].path }, path === null || path === void 0 ? void 0 : path.split("?")[0]))
            return true;
    }
    return false;
}
exports.default = pathInRoutes;
