"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function alertError(error) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    if ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) {
        let message = '';
        for (const property in (_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.errors) {
            message += `${property}: ${(_f = (_e = error === null || error === void 0 ? void 0 : error.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.errors[property]}\n`;
        }
        alert(message);
    }
    else if (((_g = error === null || error === void 0 ? void 0 : error.response) === null || _g === void 0 ? void 0 : _g.status) == 404) {
        alert('exception: ' + ((_j = (_h = error === null || error === void 0 ? void 0 : error.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.exception) + '\n'
            + 'url: ' + ((_l = (_k = error === null || error === void 0 ? void 0 : error.response) === null || _k === void 0 ? void 0 : _k.config) === null || _l === void 0 ? void 0 : _l.url));
    }
    else if (((_m = error === null || error === void 0 ? void 0 : error.response) === null || _m === void 0 ? void 0 : _m.status) == 401) {
        alert('message: Unauthenticated \n'
            + 'url:  ' + ((_p = (_o = error === null || error === void 0 ? void 0 : error.response) === null || _o === void 0 ? void 0 : _o.config) === null || _p === void 0 ? void 0 : _p.url));
    }
    else if (((_q = error === null || error === void 0 ? void 0 : error.response) === null || _q === void 0 ? void 0 : _q.status) == 500) {
        alert('message: ' + ((_s = (_r = error.response) === null || _r === void 0 ? void 0 : _r.data) === null || _s === void 0 ? void 0 : _s.message) + '\n'
            + 'url: ' + ((_u = (_t = error === null || error === void 0 ? void 0 : error.response) === null || _t === void 0 ? void 0 : _t.config) === null || _u === void 0 ? void 0 : _u.url));
    }
}
exports.default = alertError;
