"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    super: 'super',
    messages_management: 'إدارة الرسائل',
    decisions_management: 'إدارة القرارات',
    documents_management: 'إدارة الأرشيف العام',
    departments: 'الإدارات والاقسام',
    form_types: 'إدارة النماذج',
    decision_form_types: 'إدارة نماذج القرارات',
    meeting_form_types: 'إدارة نماذج المحاضر',
    employees_managment: 'إدارة شؤون الموظفين',
    university_mangement: 'إدارة الجامعة',
    meetings: 'محاضر الاجتماعات',
};
