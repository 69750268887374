"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeProperty = void 0;
function changeProperty(dispatchColumns, property, value) {
    dispatchColumns({ actionType: 'change property', property: property, value: value });
}
exports.changeProperty = changeProperty;
function objectUseReducerFunction(object, action) {
    let newobject = Object.assign({}, object);
    switch (action.actionType) {
        case 'set object':
            return action.object;
        case 'change property':
            newobject[action.property] = action.value;
            return newobject;
        case 'remove property':
            delete newobject[action.property];
            return newobject;
        case 'add property':
            newobject[action.property] = action.value;
            return newobject;
        case 'add object properties':
            console.log('add object', (action.object));
            return Object.assign(Object.assign({}, newobject), action.object);
    }
    return object;
}
exports.default = objectUseReducerFunction;
